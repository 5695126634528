@font-face {
    font-family: 'Avenir Next Cyr';
    src: url('AvenirNextCyr-Bold.woff2') format('woff2'),
        url('AvenirNextCyr-Bold.woff') format('woff');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gropled';
    src: url('GropledBold.woff2') format('woff2'),
        url('GropledBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

