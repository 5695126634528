@import './variables/variables';
@import '../fonts/stylesheet.css';

.inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.fix-dropdown {
  &:hover {
    color: $primary !important;
  }
  &:active {
    color: white !important;
  }
}

body {
  background-color: red;
}

.np-color {
  color: #40a89c !important;
}

.np-background {
  background-color: #40a89c !important;
}

.np-hover:hover {
  box-shadow: 0 8px 25px -8px #40a89c;
}

.small-br {
  display: block !important;
  margin: -10px !important;
  content: "" !important;
}